import type { Strapi4ResponseData, Strapi4ResponseSingle, Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types'
import type { PaginationMeta } from '~/types/paginationMeta'

type TransformedWithMeta<T> = {
    data: T[],
    meta: {
        pagination: PaginationMeta
    }
}

export function transformManyWithMeta<T> (response: Strapi4ResponseMany<T>): TransformedWithMeta<T> {
    const transformedData = response.data.map(transform)
    const meta: PaginationMeta = response.meta.pagination as PaginationMeta
  
    return {
      data: transformedData,
      meta: {
        pagination: meta
      }
    }
  }

export function transformSingle<T>(data: Strapi4ResponseSingle<T>) {
    return transform<T>(data.data)
}

export function transformMany<T>(data: Strapi4ResponseMany<T>) {
    return {
        data: data.data.map(transform),
        meta: data.meta
    };
}

export function transformFirstOfMany<T>(data: Strapi4ResponseMany<T>) {
    return transform<T>(data.data[0])
}

export function transform<T>(data: Strapi4ResponseData<T>) {
    const result = flattenObject(data)
    return result as T
}
  
function flattenObject(ob: any): any {
    let result: any = {}
  
    if (Array.isArray(ob)) {
        return ob.map(flattenObject)
    }
  
    if (!ob?.attributes) {
        return ob
    }
  
    for (const key in ob.attributes) {
        if (Array.isArray(ob.attributes[key])) {
            result[key] = ob.attributes[key].map((array: any) => {
                const combinedResult = Object.keys(array).reduce((acc:any, row:any) => {
                    const item = processItem(array[row])

                    // If the relationship array ( articles ) ends with an 's', we check if the relations start with that name without the s ( article )
                    // When that is the case, we combine the results so we get easy data to work with.
                    if (key.endsWith('s') && key.slice(0, -1) === row) {
                        return { ...acc, ...item }
                    }

                    acc[row] = item
                    return acc
                }, {});
                return combinedResult
            });
        } else {
            result[key] = processItem(ob.attributes[key])
    
            if (typeof result[key] === 'object') {
                for (const childKey in result[key]) {
                    result[key][childKey] = processItem(result[key][childKey])
                }
            }
        }
    }
  
    return {
        id: ob.id,
        ...result
    }
}

  
function processItem(item: any) {
    if (item?.data) {
        return flattenObject(item.data)
    }
    
    return item;
}